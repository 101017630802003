import { NavLink } from 'react-router-dom'
import { withRouter } from "react-router";
import React, { useEffect, useState } from 'react';

function Nav(props) {
	const [ username, setUsername ] = useState('');

	useEffect(() => {
		fetch('/api/auth/user', {
			credentials: "include"
		})
		.then(res => {
			return res.json()
		})
		.then(data => {
			setUsername(data.user.name.match(/[^\s]+/))
		})
		.catch(function(err) {
			$('#alert').show().addClass('alert-danger').text("Could not fetch events, please refresh the page");
			hideAlert();
		})
	}, [setUsername]);

	function handleLogout() {
		const { history } = props;

		fetch('/api/auth/logout', {
			credentials: "include"
		})
		.then(res => {
			if (res.status === 200) {
				props.history.push('/login');
			} else {
				const error = new Error(res.error);
				throw error;
			}
		})
		.catch(function(err) {
			console.log('err in logout process', err);
			$('#alert').show().addClass('alert-danger').text("Unsuccessful Logout, please try again");
			hideAlert();
		})
	}
	/*\
	|*| Hide alert after 3 seconds.
	\*/
	function hideAlert() {
		$("#alert").delay(3000).slideUp(200, function() {
			$(this).alert('hide');
		});
	}

	return (
		<div className="nav-container">
			<nav className="navbar navbar-expand-sm navbar-dark bg-dark">
				<span className="navbar-brand"><i className="fa fa-picture-o" aria-hidden="true"></i>  ALH Board</span>
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>

				<div className="collapse navbar-collapse" id="navbarTogglerDemo02">
					<ul className="navbar-nav mr-auto mt-2 mt-sm-0">
						<li className={window.location.pathname === "/" ? "nav-item active" : "nav-item"}>
							<NavLink exact className="nav-link" to="/" activeClassName="active-link">Schedule </NavLink>
						</li>
						<li className={window.location.pathname === '/settings' ? "nav-item active" : "nav-item"}>
							<NavLink exact className="nav-link" to="/settings" activeClassName="active-link">Settings</NavLink>
						</li>
						<li className={window.location.pathname === '/report' ? "nav-item active" : "nav-item"}>
							<NavLink exact className="nav-link" to="/report" activeClassName="active-link">Report</NavLink>
						</li>
					</ul>
					<div className="btn-group profile-dropdown">
						<button type="button" className="btn btn-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Welcome, {username}
						</button>
						<div className="dropdown-menu dropdown-menu-right">
							<button className="dropdown-item" type="button" onClick={handleLogout}>
								Logout
							</button>
						</div>
					</div>
				</div>
			</nav>
		</div>
	)
}

export default withRouter(Nav);
