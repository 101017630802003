import React from 'react';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from './async';
import withAuth from './withAuth';

//lazy load main components
const Login = asyncComponent(() =>
	import(/*webpackChunkName: 'login'*/'../auth/login').then(module => module.default)
)
const Schedule = asyncComponent(() =>
	import(/*webpackChunkName: 'schedule'*/'../schedule/main_schedule').then(module => module.default)
)
const Report = asyncComponent(() =>
	import(/*webpackChunkName: 'report'*/'../report/report').then(module => module.default)
)

const Settings = asyncComponent(() => 
	import(/*webpackChunkName: 'report'*/'../settings/settings').then(module => module.default)
)

class Main extends React.Component {
	render() {
		return (
			<div className="router-container">
				<Switch>
					<Route exact path="/" component={withAuth(Schedule)} />
					<Route path="/login" component={Login} />
					<Route path="/report" component={withAuth(Report)} />
					<Route path="/settings" component={withAuth(Settings)} />
				</Switch>
			</div>
		)
	}
}

export default Main;
