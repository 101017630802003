import React from 'react';
/*\
|*| Import any relevant components
\*/
import Main from './router/main';
// import Nav from './template/nav';
// import { AlertProvider } from './alert/AlertProvider';
// import Alert from './alert/Alert';

const App = () => (
  // <AlertProvider>
  //   <div className="app-container">
  //     <Alert />
  //     <Main />
  //   </div>
  // </AlertProvider>
  <div className='app-container'>
    <div id="alert" className="alert hide" role="alert"></div>
    <Main />
  </div>
)

export default App;
